import React, { useCallback, useEffect, useReducer, useState } from "react";
import eventBus from "../services/eventBus";
import {
  applicationBootsrap,
  applicationMiddlewareReducer,
  getAuthToken,
  getLastGuideFromLocalStorage,
  getLocationsGraph,
  getQuoteParamsFromLocalStorage,
  getReceiverDetailsFromLocalStorage,
  getSenderDetailsFromLocalStorage,
} from "./applicationReducer";

const Store = React.createContext();

Store.displayName = "Store";

export const useStore = () => React.useContext(Store);

export const StoreProvider = ({ children, initialState, reducer }) => {
  // // Se declara el estado global que se va a querer cambiar
  const [globalState, dispatch] = useReducer(reducer, initialState);
  // this dispatcher middleware can trigger sync or async actions, from functions or direct objects
  // memorize the this function to pass it to useeffect and have only one of these

  // ignore the warning from eslint, it just means it doesnt know the params the callback expects
  // in this case, the callback expects the actions that shoudl change the state, so no big issue there
  // check https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  // use a ref instead of a usecallback

  // --eslint-disable-next-line react-hooks/exhaustive-deps
  //* Se ejecutara cada que cambien sus dependencias
  const middleWareDispatcher = useCallback(
    applicationMiddlewareReducer(dispatch, eventBus),
    []
  );

  // const middleWareDispatcher = applicationMiddlewareReducer(dispatch)

  useEffect(() => {
    // console.log("store mounted", "APP_VERSION");
    middleWareDispatcher(applicationBootsrap());
    // this loads the token async and then passes it to the dispatcher
    // try creating the middleware callback from here
    // like: useCallback( applicationMiddlewareReducer(dispatch) ,[])
    // something
    middleWareDispatcher(getAuthToken());

    // get previously saved params from localStorage
    middleWareDispatcher(getSenderDetailsFromLocalStorage());
    middleWareDispatcher(getReceiverDetailsFromLocalStorage());
    middleWareDispatcher(getQuoteParamsFromLocalStorage());
    middleWareDispatcher(getLastGuideFromLocalStorage());
    // load graph data
    middleWareDispatcher(getLocationsGraph());
  }, [middleWareDispatcher]);

  // inject the middleware dispatcher
  return (
    <Store.Provider value={[globalState, middleWareDispatcher, eventBus]}>
      {children}
    </Store.Provider>
  );
};
