import { API_ROOT_URL, PIPEDREAM_ROOT_URL, API_AEROCHARTER } from "../constants";
import { createPaymentIntent } from "./backend";
import { storeGuide, storeRoutes, getRoutes } from "./sessionStorage";
import { sendEmail } from "./backend";
import { useStore } from "app/store/store";
import { async } from "validate.js";
import { ACCEPTCP, NOACCEPTCP } from "../constants";
import { getItemStorage } from "../../hooks/useStorage";

export const validateSender = async ({ receiver, sender }) => {
  try {
    const response = await fetch(
      `${API_AEROCHARTER}/shipments/check-postcode`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "origin_postcode": sender,
          "destination_postcode": receiver
        })
      }
    )
    const json = await response.json()
    const { status, message } = json
    if (status === 'ok' && message === 'servicio disponible') {
      return { message, data: ACCEPTCP }
    }
    return { message, data: NOACCEPTCP }
  } catch (err) {
    return { message: 'Error de conexión con el servidor, intente más tarde' }
  }
}


function isTokenExpired(token) {
  const encodedData = token.split(".")[1];
  const tokenData = encodedData ? atob(encodedData) : null;
  return tokenData ? JSON.parse(tokenData).exp < Date.now() / 1000 : true;
}

export const login = async function () {
  // check the local storage for the token first
  const token = localStorage.getItem("token");
  // if the token is there and is not expired, return the token
  if (token && !isTokenExpired(token)) {
    // console.log("token from global storage", token)
    // console.log("token is valid and has not expired");
    return {
      OK: true,
      token,
    };
  }

  const response = await getToken().catch(console.error);
  return response;
};

export const retrieveRoutes = async () => {
  // load from local storage first
  const _routes = getRoutes();
  // check if routes data is not older than 6 hrs
  if (
    _routes &&
    _routes.lastUpdated &&
    Date.now() - _routes.lastUpdated < 6 * 60 * 60 * 1000
  ) {
    return { success: true, payload: _routes.data };
  }

  try {
    const response = await fetch(
      `https://aerocontrol-api.herokuapp.com/api/extra/matriz`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        // console.error(err);
        return { error: err };
      });

    if (response.Error || response.error) {
      // console.log("an error ocurred while retrieving routes", response)
      // default to the local storage or empty data
      return {
        success: true,
        payload: getRoutes().data ? getRoutes().data : [],
      };
    }
    // if (response.Error) return { success: false, message: response.Error }
    // if(response.error) return { success: false, message: response.error.message }

    // let cleanEntries = Object.entries(response.data).map((el) => [el[0].replaceAll(" ", "_").replaceAll("/", "_").toLowerCase(), el[1]])
    response.data = response["data:"] ? response["data:"] : response.data;

    // let cleanData = []
    // cleanEntries = Object.fromEntries(cleanEntries)

    storeRoutes({ data: response.data, lastUpdated: Date.now() });
    return { success: true, payload: response.data };
  } catch (err) {
    // console.error(err);
    return {
      success: false,
      message: "Error al obtener las rutas",
    };
  }
};

const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

// * COTIZACIÓN = "Cotizar envío"
export const getQuote = async ({ token, origin, destination, weight, length, height, width }) => {
  if (origin === "Nuevo León" || origin === "Querétaro" || origin === "Yucatán")
    origin = removeAccents(origin);
  if (
    destination === "Nuevo León" ||
    destination === "Querétaro" ||
    destination === "Yucatán"
  )
    destination = removeAccents(destination);
  // * Validaciones
  //console.log("val::", origin, destination)
  if (!origin) return Promise.reject("this endpoint requires an origin state");
  if (!destination)
    return Promise.reject("this endpoint requires an destination state");
  if (!weight)
    return Promise.reject(
      "this endpoint requires a weight specification in KG"
    );
  if (!token) return Promise.reject("this endpoint requires an access token");
  // check for terms acceptance
  if (isTokenExpired(token)) {
    // refresh token
    token = (await getToken()?.token) || null;
  }
  // * estado_origen
  // *estado_destino
  // * peso
  // ! Los parámetros anteriores son los únicos en el endpoint actualizados
  try {
    let response = await fetch(`${API_AEROCHARTER}/shipments/quote`, {
      method: "POST",
      headers: makeHeaders(token),
      body: JSON.stringify({
        estado_origen: origin,
        estado_destino: destination,
        largo: length,
        ancho: width,
        alto: height,
        peso: weight,
      }),
    })
      .then((res) => res.json())
      .catch((err) => {
        return { error: err };
      });
    // test for bad responses, test the response code
    // validate errors
    if (response.Error) return { success: false, message: response.Error };
    if (response.error)
      return { success: false, message: response.error.message };
    // fix the object keys invalid naming
    response.data = response["data:"] ? response["data:"] : response.data;
    let cleanEntries = Object.entries(response.data).map((el) => [
      el[0].replaceAll(" ", "_").replaceAll("/", "_").toLowerCase(),
      el[1],
    ]);
    cleanEntries = Object.fromEntries(cleanEntries);
    cleanEntries.nodos = [...response.nodos];
    cleanEntries.nodos = cleanEntries.nodos
      .filter((el) => el !== "N/A")
      .filter((el) => el !== "");
    // use the data to create a payment intent
    const client_secret = await createPaymentIntent(cleanEntries.costo);
    cleanEntries.client_secret = client_secret.client_secret;
    return { success: true, payload: cleanEntries };
  } catch (error) {
    return { success: false, message: error };
  }
};
// * Consultar guía, CUANDO A ESTA CREADA PÁGINA => Rastreo
export const getGuideById = async (token, guideId) => {
  if (!token) return Promise.reject("this endpoint requires an access token");
  if (isTokenExpired(token)) {
    // refresh token
    token = (await getToken()?.token) || null;
  }
  try {
    let response = await fetch(`${API_AEROCHARTER}/shipments/id/${guideId}`, {
      method: "GET",
      headers: makeHeaders(token),
    })
      .then((res) => res.json())
      .catch((err) => {
        // console.log(err);
        return { ERROR: err.name, mensaje: "No pudimos procesar la solicitud" };
      });
    // validate errors
    if (response.ERROR) return { success: false, message: response.mensaje };
    // fix the object keys invalid naming
    response.data = response["data:"] ? response["data:"] : response.data;
    // save guide to localstorage ??
    // storeGuide(response.data)
    // create steps
    response.data.steps = decodeSteps(response.data);
    return { success: true, payload: response.data };
  } catch (error) {
    return { success: false, message: error };
  }
};


export const createGuideService = async (
  token,
  requestParams,
  paymentIntent
) => {
  const paymentData = JSON.parse(sessionStorage.getItem("paymentData"))
  // ** Agregar la función de mandar emai
  if (paymentData.status === 'approved') {
    if (!token) return Promise.reject("this endpoint requires an access token");
    if (isTokenExpired(token)) {
      console.log("EXXPIROO")
      token = (await getToken()?.token) || null;
    }
    try {
      // /orders/place-order
      let response = await fetch(`${API_AEROCHARTER}/shipments/place-order`, {
        method: "POST",
        headers: makeHeaders(token),
        body: JSON.stringify(requestParams),
        // mode: 'no-cors'
      })
        .catch((err) => {
          return err;
        })
        .then((res) => res.json());
      // validate errors
      // sessionStorage.removeItem("saveSendEmail")
      if (response.ERROR) return { success: false, message: response.mensaje };
      // fix the object keys invalid naming
      response.data = response["data:"] ? response["data:"] : response.data;
      // save guide to localstorage
      storeGuide(response.data);
      return { success: true, payload: response.data };
    } catch (error) {
      return { success: false, message: error };
    }
  } else {
    return { success: false, message: "El pago de Mercado Pago no pudo procesarse" };
  }
};

const makeHeaders = (token) => ({
  "x-access-token": token,
  "Content-Type": "application/json",
});

const getToken = async () => {
  let response = await fetch(`${PIPEDREAM_ROOT_URL}/auth`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .catch((err) => {
      console.log(err);
      return err;
    })
    .then((res) => res.json());
  if (response.success === true) {
    localStorage.setItem("token", response?.token);
    response.OK = true;
  }
  // failsafe
  if (response && response.OK) {
    localStorage.setItem("token", response?.token);
  }

  return response;
  // * test => server
  //if (response.body.success === true) {
  //  localStorage.setItem("token", response.body.token);
  //  response.OK = true;
  //}
  //// failsafe
  //if (response && response.OK) {
  //  localStorage.setItem("token", response.body.token);
  //}
  //return response.body;
};

const decodeSteps = (guide) => {
  //   console.log(guide);
  let [nodes, statusCode, creationDate, updatedDate, deliverEstimate] = [
    guide.nodos,
    guide.status_code,
    new Date(guide.createdAt).toLocaleDateString("es"),
    new Date(guide.updatedAt).toLocaleDateString("es"),
    guide.fecha_entrega,
    guide.origen,
    guide.destino,
  ];

  let destinationStep = {
    location: "Entrega de paquete",
    ...getStatusMessage(
      8,
      statusCode,
      creationDate,
      updatedDate,
      deliverEstimate
    ),
  };

  let midSteps = nodes.map((el, ind) => {
    return {
      location: el,
      ...getStatusMessage(
        ind,
        statusCode,
        creationDate,
        updatedDate,
        deliverEstimate
      ),
    };
  });

  return [...midSteps, destinationStep];
};

// a test step is used to try and match the status code curretn step  ( first integer )
const getStatusMessage = (
  step,
  statusCode,
  creationDate,
  updatedDate,
  deliverEstimate
) => {
  const [node, error] = statusCode.split("").map((el) => parseInt(el));

  // if desired step is current step, put last update date
  let displayDate = node === step ? updatedDate : "";
  let displayError = error > 0 ? getErrorType(error) : null;

  return {
    status:
      node > step
        ? "DONE"
        : node === step && error === 0
          ? "CURRENT"
          : "PENDING",
    description:
      node > step
        ? "concluido"
        : node === step && error === 0
          ? getMessageType(node)
          : "en espera",
    date:
      step === 0
        ? creationDate
        : step === 8 && node !== 8
          ? deliverEstimate
          : displayDate,
    error: step === node ? displayError : null,
  };
};

const getMessageType = (node) => {
  //   console.log("received node", node);
  const messageTypes = new Map([
    [0, "solicitud en proceso"],
    [1, "preparando tu pedido"],
    [2, "pedido en camino"],
    [3, "pedido en camino"],
    [4, "pedido en camino"],
    [5, "pedido en camino"],
    [6, "pedido en camino"],
    [7, "pedido en camino"],
    [8, "paquete entregado"],
  ]);
  return messageTypes.get(node);
};

const getErrorType = (errorNumber) => {
  const errorTypes = new Map([
    [0, "OK"],
    [1, "Hubo un problema al procesar tu paquete"],
    [2, "Hay un error en la información de tu paquete"],
    [3, "Paquete dañado"],
  ]);

  return errorTypes.get(errorNumber) || "Error desconocido";
};

export const getAirportDetails = async (airportCode) => {
  console.log("Codigo aeropuerto", airportCode);
  if (!airportCode)
    return Promise.reject({
      error: "invalid arguments",
      message: "Airport code is required",
    });
  // ?? implement a proper api call to retrieve this values
  // ! ?????????
  if (airportCode === "senderDetails") {
    return Promise.resolve({
      street: "Airport A Street",
      exterior: "0",
      city: "Airport A City",
      postalcode: "54321",
      state: "Ciudad de México",
      reference_street: "",
      colony: "Airport A Colony",
      municipality: "Airport A Municipality",
    });
  }

  if (airportCode === "receiverDetails") {
    return Promise.resolve({
      street: "Airport B Street",
      exterior: "0",
      city: "Airport B City",
      postalcode: "12345",
      state: "Ciudad de México",
      reference_street: "",
      colony: "Airport B Colony",
      municipality: "Airport B Municipality",
    });
  }

  return Promise.reject({
    error: "not found",
    message: "airport code not found",
  });
};
