import {
  login,
  getQuote,
  createGuideService,
  retrieveRoutes,
} from "../services/aerocharter"
import {
  fillGraphData,
  LocationsGraph,
} from "../services/data/locations/graph"
import eventBus from "../services/eventBus"
import {
  checkStaleData,
  getGuide,
  getQuoteParams,
  getReceiverDetails,
  getSenderDetails,
  storeQuoteParams,
  storeReceiverDetails,
  storeSenderDetails,
} from "../services/sessionStorage"
import { sendEmail } from "../services/backend"

export const APP_BOOTSTRAP = "APP/APPLICATION_BOOTSTRAP"
export const UPDATE_STATE_INFO = "APP/UPDATE_STATE_INFO"
export const GET_AUTH_TOKEN = "APP/GET_AUTH_TOKEN"
export const UPDATE_QUOTE_PARAMS = "APP/UPDATE_QUOTE_PARAMS"
export const GET_QUOTE = "APP/GET_QUOTE"
export const LOAD_ROUTES = "APP/LOAD_ROUTES"

export const QUOTE_FAILED = "APP/ERROR/QUOTE_FAIL"
export const QUOTE_SUCCESS = "APP/QUOTE_SUCCESS"

export const CAPTURE_SENDER_DETAILS = "APP/CAPTURE_SENDERDETAILS"
export const CAPTURE_RECEIVER_DETAILS = "APP/CAPTURE_RECEIVERDETAILS"
export const CAPTURE_FACTURACION = "APP/CAPTURE_FACTURACION"

export const CREATE_GUIDE = "APP/CREATE_GUIDE"
export const GUIDE_CREATE_SUCCESS = "APP/GUIDE_CREATE_SUCCESS"
export const GUIDE_CREATE_FAILED = "APP/GUIDE_CREATE_FAILED"

export const initialState = {
  appStateInfo: null,
  nodes: [],
  edges: [],
  authToken: null,
  senderDetails: {},
  receiverDetails: {},
  quote: {
    params: {
      origin: "",
      destination: "",
      weight: "",
    },
    result: {},
  },
  guide: null,
}

// actions, we will put some business logic here

export const applicationBootsrap = () => {
  // clean the local storage if the version has changed
  checkStaleData()
  return {
    type: APP_BOOTSTRAP,
    payload: {},
  }
}
export const getQuoteParamsFromLocalStorage = () => {
  return {
    type: UPDATE_QUOTE_PARAMS,
    payload: getQuoteParams() || {},
  }
}
// * Pre Cotización
export const getQuoteFor = async ({ token, origin, destination, weight, length, height, width }) => {
  // ?? transform origin for cdmx
  // let state_origin_mx = ORIGIN_DATA.map((m) => console.log(m));
  const quote = await getQuote({ token, origin, destination, weight, length, height, width })
    .then((res) => {
      return res.success ? res.payload : { error: res.message }
    })
    .catch((error) => ({ error: error }))
  // * Sacar estos mensajes a un archivo aparte notifications.js
  if (quote.error) {
    eventBus.publish("NOTIFICATION", {
      title: "Ups!",
      message: "No pudimos cotizar tu envío",
      type: "error",
    })
    return {
      type: QUOTE_FAILED,
      payload: quote,
    }
  }

  eventBus.publish("NOTIFICATION", {
    title: "Cotización lista!",
    message: "Ya tenemos tu cotización",
    type: "success",
  })
  return {
    type: QUOTE_SUCCESS,
    payload: quote,
  }
}

export const getAuthToken = async () => {
  const authToken = await login().then((res) => {
    if (res.OK) {
      return res.token
    }
    console.log("token is invalid or empty", authToken)
    return null
  })
  // return a different action when the token isnt available
  // console.log(authToken)
  return {
    type: GET_AUTH_TOKEN,
    authToken,
  }
}

export const getSenderDetailsFromLocalStorage = () => {
  return {
    type: CAPTURE_SENDER_DETAILS,
    payload: getSenderDetails() || {},
  }
}

export const updateApplicationStateInfo = (info = null) => {
  return {
    type: UPDATE_STATE_INFO,
    payload: info,
  }
}
export const getReceiverDetailsFromLocalStorage = () => {
  return {
    type: CAPTURE_RECEIVER_DETAILS,
    payload: getReceiverDetails() || {},
  }
}

export const getLastGuideFromLocalStorage = () => {
  return {
    type: GUIDE_CREATE_SUCCESS,
    payload: getGuide() || {},
  }
}

export const getLocationsGraph = async () => {
  const routes = await retrieveRoutes()
    .then((res) => res)
    .catch((error) => error)
  if (routes.success === true) {
    // transform the routes into a graph
    // console.log(routes.payload)
    // transform the payload to edges and nodes
    fillGraphData(routes.payload)
    return {
      type: LOAD_ROUTES,
      payload: {
        nodes: LocationsGraph.nodes(true).map((n) => n[1]),
        edges: LocationsGraph.edges(true),
      },
    }
  }
  // dont do anything if theres no routes
  return {
    type: "",
    payload: null,
  }
}

export const createGuide = async (state, paymentIntentResult) => {
  // deconstruct sender and receiver details
  const { senderDetails, receiverDetails } = state
  const quoteDetails = state.quote.result
  const quoteParams = state.quote.params
  const stateOrigin = state.quote.params.origin
  const stateDestination = state.quote.params.destination
  const packageDescription = state.quote.params.merchandise
  const { dias } = state.quote.result
  const { estado_destino, estado_origen } = state.quote.result
  localStorage.setItem(estado_destino, estado_origen)
  // * Fecha estimada
  let deliveryDate = new Date()
  deliveryDate = new Date(
    deliveryDate.setDate(deliveryDate.getDate() + parseInt(dias))
  ).toLocaleDateString("es")
  const requestBody = {
    costo: quoteDetails.costo,
    // * Lo obtenemos de la otra ruta
    dias: dias,
    servicio: quoteParams.serviceType,
    // what does this mean and where to get it
    // lets assume today + number of days on the quote result
    fecha_entrega: deliveryDate,
    // @todo: how to get this and where ??
    ruta: quoteDetails.ruta,
    // @todo: not sending nodes creates a guide with ...no nodes
    nodos: quoteDetails.nodos,
    origen: {
      nombre: `${senderDetails.first_name} ${senderDetails.last_name}`,
      email: senderDetails.email,
      telefono: senderDetails.phone,
      calle: senderDetails.street,
      exterior: senderDetails.exterior,
      interior: senderDetails.interior,
      colonia: senderDetails.colony,
      cp: senderDetails.postalcode,
      ciudad: senderDetails.city,
      municipio: senderDetails.municipality,
      estado: stateOrigin,
      // Descripción domicilio faltaria
    },
    destino: {
      nombre: `${receiverDetails.first_name} ${receiverDetails.last_name}`,
      email: receiverDetails.email,
      telefono: receiverDetails.phone,
      calle: receiverDetails.street,
      exterior: receiverDetails.exterior,
      interior: receiverDetails.interior,
      colonia: receiverDetails.colony,
      cp: receiverDetails.postalcode,
      ciudad: receiverDetails.city,
      municipio: receiverDetails.municipality,
      estado: stateDestination,
      // Descripción domicilio faltaria
    },
    // ? where to get this???
    paquete: {
      descripcion: packageDescription,
      ancho: 5,
      alto: 12,
      profundidad: 8,
      peso: parseInt(quoteParams.weight),
    },
  }
  // console.log("create a guide for this", requestBody, paymentIntentResult);
  let guide = await createGuideService(
    state.authToken,
    requestBody,
    paymentIntentResult
  ).catch((error) => error)

  //console.log("guide created or something", guide)
  const { origen, destino } = guide?.payload
  await sendEmail(guide?.payload, senderDetails, receiverDetails)
  if (guide.error) {
    eventBus.publish("NOTIFICATION", {
      title: "Error al crear la guía",
      message: guide.error,
      type: "error",
      duration: 2000,
    })
    return {
      type: GUIDE_CREATE_FAILED,
      payload: guide,
    }
  }

  eventBus.publish("NOTIFICATION", {
    type: "success",
    title: "Pago completado",
    message: "Guía creada con éxito",
    duration: 2000,
  })
  // navigate("/pago-completado")

  return {
    type: GUIDE_CREATE_SUCCESS,
    payload: guide.payload,
  }
}

// store reducer
// if the action and specific payload is not here, the data wont be merged
// we can automate the payload merging by using a payload object like:
// { type:Obj, payload:Obj }
// and then simply merging the data like:
// return { ...state, ...action?.payload }
export const applicationReducer = (state = initialState, action) => {
  // console.log("apply state for action", action.payload)
  // console.log("state", state.quote.params)
  switch (action.type) {
    case GUIDE_CREATE_SUCCESS:
      return {
        ...state,
        guide: action.payload,
        quote: {
          params: { ...state.quote.params },
          result: {},
        },
      }
    case UPDATE_STATE_INFO:
      return {
        ...state,
        appStateInfo: action.payload,
      }
    case CAPTURE_RECEIVER_DETAILS:
      return {
        ...state,
        receiverDetails: {
          ...state.receiverDetails,
          ...action.payload,
        },
      }
    case CAPTURE_SENDER_DETAILS:
      return {
        ...state,
        senderDetails: {
          ...state.senderDetails,
          ...action.payload,
        },
      }
    case QUOTE_SUCCESS:
      return {
        ...state,
        quote: {
          params: state.quote.params,
          result: action.payload,
        },
      }
    case QUOTE_FAILED:
      return {
        ...state,
        quote: {
          params: state.quote.params,
          result: action.payload,
        },
      }
    case GET_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.authToken,
      }
    case UPDATE_QUOTE_PARAMS:
      return {
        ...state,
        quote: {
          params: action.payload,
          result: state.quote.result,
        },
      }
    case LOAD_ROUTES:
      return {
        ...state,
        nodes: action.payload.nodes,
        edges: action.payload.edges,
      }
    default:
      // console.log("default state merge, doesnt change the state", action);
      return {
        ...state,
        // ...action?.payload
      }
  }
}

// write here the logic for the application, calling services, etc
// this is the dispatcher from the store reducer
// our middleware can handle non async actions for now
// it can also publish to the event bus to keep the application lyfecicle running
export const applicationMiddlewareReducer = (dispatch, eventBus) => {
  // @todo this is being triggered after updating the ui... find out where
  // it probably is being done in the state
  //   console.log("middleware created con reduce");

  return (action) => {
    // console.log("middleware action callback  created")
    // check if the action is a promise or not
    if (!action.then) {
      // console.log("process the action sync", action)
      switch (action.type) {
        case CAPTURE_RECEIVER_DETAILS:
          // save receiver details to local storage
          storeReceiverDetails(action.payload)
          dispatch(action)
          break
        case CAPTURE_SENDER_DETAILS:
          // save sender details to local storage
          console.log(action)
          storeSenderDetails(action.payload)
          dispatch(action)
          break
        case UPDATE_QUOTE_PARAMS:
          // save the quote form  params to localstorage
          storeQuoteParams(action.payload)
          dispatch(action)
          break
        default:
          dispatch(action)
          break
      }
      return
    }

    action
      .then((responseAction) => {
        // console.log("process the async action")
        // console.log("action response", responseAction.type)
        // using this swithc we can further transform the response data
        // we could get rid of this switch and then simply pipe the data to the dispatcher
        switch (responseAction.type) {
          case GET_AUTH_TOKEN:
            dispatch(responseAction)
            break
          case GET_QUOTE:
            dispatch(responseAction)
            break
          case QUOTE_SUCCESS:
            eventBus.publish("QUOTE_RETRIEVED")
            dispatch(responseAction)
            break
          case QUOTE_FAILED:
            // trigger event with no payload
            eventBus.publish("QUOTE_FAILED")
            dispatch(responseAction)
            break
          default:
            dispatch(responseAction)
            break
        }
      })
      .catch((err) => {
        // console.log("error in the application middleware reducer");
        console.error(err)
      })
  }
}
